/* eslint-disable */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  authUserObj,
  cookieFilters,
  cookieGeoTabPath,
  cookiePagination,
  cookieSearch,
  cookieSort,
  cookiesSessionUser,
  credentials,
  geotabGroups
} from '@common/services/auth';
import { routes } from '@common/utils/route-names';
import { setAuthUser, setIsMetric, setTimeZoneId } from '@store/features/auth';
import { useAppDispatch } from '@store/hooks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  IAgencyList,
  IBillingDetails,
  IGeotabUser,
  IGeotabVehicle,
  IGeotabVehiclePlan,
  IState,
  ITagTypes
} from '../types';
import Cookies from 'js-cookie';
import { useLoginUserAPIMutation, useValidateUserAPIMutation } from '@api/services/geotab-sso';
import { rtkqController } from '@api/config/base-api';
import { getStatesOfCountry } from '@common/utils/constants/helpers';
import axios from 'axios';
import { AUTH_BE_URL } from '@api/config';
import { setDefaultHeadings } from '@store/features/headings';
import { setSessionFilters } from '@store/features/filters';
import { setSessionSearch } from '@store/features/search';
import { setSessionSort } from '@store/features/sort';
import { setSessionPagination } from '@store/features/pagination';
import { useGetUserRolePermsMutation } from '@api/services/auth';
import { getTagTypes, getStatementTypes } from '@store/data/fleetTabs';
import { toast } from 'react-toastify';
import { useSSOContext } from '../context/SSOContext';
import { tabs } from '../utils/tabs';

export const useValidateGeotabUser = () => {
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [database, setDatabase] = useState<string | undefined>(undefined);
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);

  useEffect(() => {
    // Add the event listener for message events
    const handleMessage = (event: MessageEvent) => {
      // Extract data from the event
      const {
        username: receivedUserName,
        database: receivedDatabase,
        sessionId: receivedSessionId
      } = event.data;
      // Save the values in localStorage
      localStorage.setItem('userName', receivedUserName);
      localStorage.setItem('database', receivedDatabase);
      localStorage.setItem('sessionId', receivedSessionId);
      setUserName(receivedUserName);
      setDatabase(receivedDatabase);
      setSessionId(receivedSessionId);
      console.log('useValidateGeotabUser event.data', event.data);
      console.log('useValidateGeotabUser', receivedUserName, receivedDatabase, receivedSessionId);
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    console.log('userName', userName);
    if (userName) {
      validateUser().catch(e => console.log(e));
    }
  }, [userName]);

  // const { userName, database, sessionId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [validateUserAPI] = useValidateUserAPIMutation();
  const [loginUserAPI] = useLoginUserAPIMutation();
  const [getUserRolePerms] = useGetUserRolePermsMutation();

  const [vehicles, setVehicles] = useState<IGeotabVehicle[]>([]);
  const [initialVehicles, setInitialVehicles] = useState<IGeotabVehicle[]>([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const states = useMemo(() => getStatesOfCountry('US'), []);
  const [paymentPlans, setPaymentPlans] = useState<IGeotabVehiclePlan[]>([]);
  const [userDetails, setUserDetails] = useState<IGeotabUser>({
    companyAddress: '',
    email: '',
    companyName: '',
    state: '',
    city: '',
    zipCode: '',
    eulaContent: '',
    isAdmin: false,
    federalID: '',
    dbaName: '',
    companyRegAddress: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    },
    companyBillingAddress: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    },
    companyShippingAddress: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    },
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    billingName: '',
    billingPhone: '',
    billingEmail: '',
    adminEmail: ''
  });
  const [lastCompletedStep, setLastCompletedStep] = useState(0);
  const [userProgressId, setUserProgressId] = useState(null);
  const [billingDetails, setBillingDetails] = useState<IBillingDetails>();
  const [issuingAgencyList, setIssuingAgencyList] = useState<IAgencyList[]>();
  const [tagTypes, setTagTypes] = useState<ITagTypes[]>();
  const [isInternalUser, setIsInternalUser] = useState(false);
  const [isCustomerNew, setIsCustomerNew] = useState(true);
  const [gStates, setGStates] = useState<IState[]>();
  const [showSsoErr, setShowSsoErr] = useState(false);
  const [loader, setLoader] = useState(true);
  const internalUserCheck = useRef(false);
  const userSubscriptionCheck = useRef(false);

  const hideErrorMessage = () => {
    setShowSsoErr(false);
  };

  const validateUser = useCallback(async () => {
    const body = {
      username: userName,
      database,
      sessionId,
      loginType: 1
    };
    console.log('test1', body.username);
    setLoader(true);
    if (body.username) {
      console.log('test2');
      let cookieSessionId: string | null = null;
      if (Cookies.get(credentials)) {
        const cookieCredentials = JSON.parse(Cookies.get(credentials) || '');
        cookieSessionId = cookieSessionId === null ? cookieCredentials.sessionId : cookieSessionId;
        if (cookieSessionId === body.sessionId) {
          if (Cookies.get(cookieFilters)) {
            dispatch(setSessionFilters(Cookies.get(cookieFilters)));
          }
          if (Cookies.get(cookieSearch)) {
            dispatch(setSessionSearch(Cookies.get(cookieSearch)));
          }
          if (Cookies.get(cookieSort)) {
            dispatch(setSessionSort(Cookies.get(cookieSort)));
          }
          if (Cookies.get(cookiePagination)) {
            dispatch(setSessionPagination(Cookies.get(cookiePagination)));
          }
        } else {
          Cookies.remove(cookieFilters, { sameSite: 'None', secure: true });
          Cookies.remove(cookieSearch, { sameSite: 'None', secure: true });
          Cookies.remove(cookieSort, { sameSite: 'None', secure: true });
          Cookies.remove(cookiePagination, { sameSite: 'None', secure: true });
        }
      }
      Cookies.set(credentials, JSON.stringify(body), { sameSite: 'None', secure: true });
      Cookies.set(geotabGroups, searchParams.get('groupFilter') || '', {
        sameSite: 'None',
        secure: true
      });
      const SA = searchParams.get('page');
      Cookies.get(credentials);
      rtkqController.abort();
      validateUserAPI(body)
        .unwrap()
        .then(async (response: any) => {
          dispatch(setIsMetric(response.data.userDetails.isMetric));
          dispatch(setTimeZoneId(response.data.userDetails.timezone));
          if (response.status && response.status === 'OK') {
            let userProgressDets: {
              userDets: IGeotabUser | null;
              vehicleDets: IGeotabVehicle[] | null;
            } = {
              userDets: null,
              vehicleDets: null
            };
            if (
              (!response.data.internalUserExist && response.data.isCustomerNew) ||
              !response.data.subscriptionStatus
            ) {
              const getUserProgressBody = {
                geotab_databasename: database,
                geotab_sessionid: sessionId,
                user_name: userName
              };
              const userProgressRes = (
                await axios.post(`${AUTH_BE_URL}/onboarding-session/get`, getUserProgressBody)
              ).data;
              if (userProgressRes && userProgressRes.isSuccess && userProgressRes.data) {
                setUserProgressId(userProgressRes.data.id);
                if (userProgressRes.data.id && userProgressRes.data.onboarding_json) {
                  if (
                    response.data.isAdmin &&
                    !response.data.isCustomerNew &&
                    !response.data.subscriptionStatus
                  ) {
                    setLastCompletedStep(tabs.length - 1);
                    if (response.data.billingDetails.stripeURL) {
                      const fetchedStripeURL = `${response.data.billingDetails.stripeURL}?prefilled_email=${userProgressRes.data.onboarding_json.companyDetails.billingEmail}&client_reference_id=${response.data.customerDets.id}`;
                      const timeoutInterval =
                        (Number(process.env.REACT_APP_PAYMENT_REDIRECTION_TIME_IN_SECS) || 2) *
                        1000;
                      setTimeout(() => {
                        if (window.top) {
                          window.top.location.href = fetchedStripeURL;
                        }
                      }, timeoutInterval);
                    }
                  } else {
                    if (isCustomerNew) {
                      setLastCompletedStep(userProgressRes.data.onboarding_json.activeStep);
                    } else {
                      setLastCompletedStep(1);
                    }
                  }
                  if (userProgressRes.data.onboarding_json.companyDetails) {
                    userProgressDets.userDets = userProgressRes.data.onboarding_json.companyDetails;
                    if (userProgressDets.userDets) {
                      userProgressDets.userDets.email = userName;
                    }
                  }
                  if (userProgressRes.data.onboarding_json.vehicleDetails) {
                    userProgressDets.vehicleDets =
                      userProgressRes.data.onboarding_json.vehicleDetails;
                  }
                }
              }
            }
            setIsInternalUser(response.data.internalUserExist);
            internalUserCheck.current = response.data.internalUserExist;
            setSubscriptionStatus(response?.data?.subscriptionStatus || false);
            userSubscriptionCheck.current = response?.data?.subscriptionStatus || false;
            setIsCustomerNew(response.data.isCustomerNew);
            if (response.data.internalUserExist && response.data.subscriptionStatus) {
              loginUserAPI(body)
                .unwrap()
                .then(async (userResponse: any) => {
                  if (userResponse.status === 'OK') {
                    if (userResponse.data) {
                      const cognitoGroup = userResponse.data.app_role;
                      const userRolePerms = await getUserRolePerms({
                        accessToken: userResponse.data.accessToken
                      }).unwrap();
                      const modAuthObj: any = {
                        ...userResponse.data
                      };
                      if (userRolePerms.isSuccess) {
                        modAuthObj.modules = userRolePerms.data;
                      }
                      const newAuth = authUserObj(modAuthObj);
                      cookiesSessionUser.set(
                        userResponse.data.email,
                        userResponse.data.accessToken,
                        '',
                        userResponse.data.refreshToken
                      );
                      dispatch(setDefaultHeadings(userResponse?.data?.ui_table_settings));
                      dispatch(
                        setAuthUser([
                          newAuth,
                          userResponse.data.attributes['custom:User_ID'] as string
                        ])
                      )
                        .unwrap()
                        .then(() => {
                          if (cognitoGroup && cognitoGroup[0] === 'Admin') {
                            const cookieValue = Cookies.get(cookieGeoTabPath);
                            const from = cookieValue
                              ? JSON.parse(Cookies.get(cookieGeoTabPath) || routes.customers)
                              : routes.customers;
                            navigate(from, { replace: true });
                          } else {
                            if (SA === 'sa') {
                              navigate(routes.service_agreement, { replace: true });
                            } else {
                              const cookieValue = Cookies.get(cookieGeoTabPath);
                              const from = cookieValue
                                ? JSON.parse(Cookies.get(cookieGeoTabPath) || '/')
                                : '/';
                              navigate(from, { replace: true });
                            }
                          }
                        })
                        .catch(e => console.log('Err in login ==> ', e))
                        .finally(() => {
                          dispatch(getTagTypes()).catch(err => {
                            console.log('Err in fetching issuing tag types ==> ', err);
                          });
                          dispatch(getStatementTypes()).catch(err => {
                            console.log('Err in fetching statement types ==> ', err);
                          });
                        });
                    }
                  }
                })
                .catch(err => {
                  setShowSsoErr(true);
                  setIsInternalUser(false);
                  console.log('Err in logging in as geotab user ==> ', err);
                });
            } else {
              const issuingAgencies = response?.data?.tagIssuingAgencyList
                ? [...response?.data?.tagIssuingAgencyList]
                : [];
              const tagTypesList = response?.data?.tagTypes ? [...response?.data?.tagTypes] : [];
              issuingAgencies.unshift({
                id: null,
                name: 'None',
                state_name: '',
                is_tag_issuing_agency: true,
                is_toll_consolidator: false
              });
              tagTypesList.unshift({
                tag_type_id: null,
                tag_type_name: 'None'
              });

              setUserDetails({
                companyAddress: !userProgressDets.userDets
                  ? response.data.companyDetails?.companyRegAddress?.street1
                  : userProgressDets.userDets.companyRegAddress?.street1,
                companySecondaryAddress: !userProgressDets.userDets
                  ? response.data.companyDetails?.companyRegAddress?.street2
                  : userProgressDets.userDets.companyRegAddress?.street2,
                email: userProgressDets.userDets
                  ? userProgressDets.userDets.email
                  : response.data.user,
                companyName: userProgressDets.userDets
                  ? userProgressDets.userDets.companyName
                  : response.data.companyDetails?.companyName,
                eulaContent:
                  response.data.eulainfo && response.data.eulainfo.eula_template
                    ? JSON.parse(response.data.eulainfo.eula_template)
                    : '',
                state: !userProgressDets.userDets
                  ? response.data.companyDetails?.companyRegAddress?.state
                  : userProgressDets.userDets.companyRegAddress?.state,
                city: !userProgressDets.userDets
                  ? response.data.companyDetails?.companyRegAddress?.city
                  : userProgressDets.userDets.companyRegAddress?.city,
                zipCode: !userProgressDets.userDets
                  ? response.data.companyDetails?.companyRegAddress?.zipCode
                  : userProgressDets.userDets.companyRegAddress?.zipCode,
                isAdmin: response.data.isAdmin,
                dbaName: !userProgressDets.userDets
                  ? response.data.companyDetails?.dbaName
                  : userProgressDets.userDets.dbaName,
                federalID: !userProgressDets.userDets
                  ? response.data.companyDetails?.federalID
                  : userProgressDets.userDets.federalID,
                companyRegAddress: !userProgressDets.userDets
                  ? response.data.companyDetails?.companyRegAddress
                  : userProgressDets.userDets.companyRegAddress,
                companyBillingAddress: !userProgressDets.userDets
                  ? response.data.companyDetails?.companyBillingAddress
                  : userProgressDets.userDets.companyBillingAddress,
                companyShippingAddress: !userProgressDets.userDets
                  ? response.data.companyDetails?.companyShippingAddress
                  : userProgressDets.userDets.companyShippingAddress,
                contactName: !userProgressDets.userDets
                  ? response.data.companyDetails?.contactName
                  : userProgressDets.userDets.contactName,
                contactPhone: !userProgressDets.userDets
                  ? response.data.companyDetails?.contactPhone
                  : userProgressDets.userDets.contactPhone,
                contactEmail: !userProgressDets.userDets
                  ? response.data.companyDetails?.contactEmail
                  : userProgressDets.userDets.contactEmail,
                billingName: !userProgressDets.userDets
                  ? response.data.companyDetails?.billingName
                  : userProgressDets.userDets.billingName,
                billingPhone: !userProgressDets.userDets
                  ? response.data.companyDetails?.billingPhone
                  : userProgressDets.userDets.billingPhone,
                billingEmail: !userProgressDets.userDets
                  ? response.data.companyDetails?.billingEmail
                  : userProgressDets.userDets.billingEmail
              });
              setPaymentPlans([
                // {
                //   type: 'Free',
                //   pricing: '0$/month',
                //   description: 'Initial level access',
                //   isActive: false
                // },
                {
                  type: 'Recommended',
                  pricing: '10$/month',
                  description: 'Limited access',
                  isActive: false
                }
                // {
                //   type: 'Enterprize level',
                //   pricing: 'Pricing varies',
                //   description: 'Customizable access',
                //   isActive: false
                // }
              ]);
              setGStates(response.data.userDetails?.states);
              if (response.data.deviceDetails?.length > 0) {
                const formattedVehicles: IGeotabVehicle[] = [];
                response.data.deviceDetails.forEach((vehicle: any) => {
                  const userVehicleProgress = userProgressDets.vehicleDets
                    ? userProgressDets.vehicleDets.find(x => x['VEHICLE ID'] === vehicle.deviceId)
                    : null;
                  const data: IGeotabVehicle = {
                    VIN: vehicle.VIN,
                    'VEHICLE NAME': vehicle.name,
                    'VEHICLE CLASS': 'CLASS_1',
                    'VEHICLE MAKE': vehicle.make || 'Unknown',
                    'VEHICLE MODEL': vehicle.model || 'Unknown',
                    'VEHICLE TYPE': vehicle.vehicleType || 'TRUCK',
                    'VEHICLE ID': vehicle.deviceId,
                    SUBSCRIPTION: 'ACTIVE',
                    'VEHICLE WEIGHT': !userVehicleProgress
                      ? 0
                      : userVehicleProgress['VEHICLE WEIGHT'],
                    'VEHICLE HEIGHT': !userVehicleProgress
                      ? 0
                      : userVehicleProgress['VEHICLE HEIGHT'],
                    'TAG ISSUING AGENCY': !userVehicleProgress
                      ? ''
                      : userVehicleProgress['TAG ISSUING AGENCY'],
                    'LICENSE PLATE NUMBER': !userVehicleProgress
                      ? vehicle.licensePlate
                      : userVehicleProgress['LICENSE PLATE NUMBER'],
                    'LICENSE PLATE STATE': !userVehicleProgress
                      ? vehicle.licenseState &&
                        states.find(x => x.isoCode === vehicle.licenseState.toUpperCase())
                        ? `${
                            states.find(x => x.isoCode === vehicle.licenseState.toUpperCase())
                              ?.isoCode
                          } - ${
                            states.find(x => x.isoCode === vehicle.licenseState.toUpperCase())?.name
                          }`
                        : ''
                      : userVehicleProgress['LICENSE PLATE STATE'],
                    'AXLE COUNT': !userVehicleProgress ? 2 : userVehicleProgress['AXLE COUNT'],
                    'TIRE COUNT': !userVehicleProgress ? 0 : userVehicleProgress['TIRE COUNT'],
                    'TAG TYPE': !userVehicleProgress ? '' : userVehicleProgress['TAG TYPE'],
                    'TAG #': !userVehicleProgress ? '' : userVehicleProgress['TAG #']
                  };
                  if (!vehicles.find(x => x.VIN === data.VIN)) {
                    formattedVehicles.push(data);
                  }
                });
                setVehicles(formattedVehicles);
                userProgressDets?.vehicleDets && setInitialVehicles(userProgressDets.vehicleDets);
              }
              setBillingDetails({
                trialStartDate: response.data.trialStartDate,
                trialEndDate: response.data.trialEndDate,
                stripeURL: response.data.stripeURL,
                ...response.data.billingDetails
              });
              setIssuingAgencyList(issuingAgencies || []);
              setTagTypes(tagTypesList || []);
            }
          }
        })
        .catch(err => {
          setShowSsoErr(true);
          setIsInternalUser(false);
          console.log('Err in validating geotab user ==> ', err);
        })
        .finally(() => {
          if (!internalUserCheck.current || !userSubscriptionCheck.current) {
            setLoader(false);
          }
        });
    }
  }, [database, navigate, sessionId, dispatch, userName, vehicles]);

  return {
    isInternalUser,
    isCustomerNew,
    subscriptionStatus,
    userProgressId,
    gStates,
    showSsoErr,
    loader,
    vehicles,
    initialVehicles,
    userDetails,
    lastCompletedStep,
    billingDetails,
    issuingAgencyList,
    tagTypes,
    paymentPlans,
    hideErrorMessage
  };
};
